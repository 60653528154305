import React, {useState} from 'react';
import '../assets/css/Gallery.css'; // Import CSS file for styling
import NavigationBar from './NavigationBar';
import PhotoGallery from './PhotoGallery';

function Gallery() {

    const [selectedNavItem, setSelectedNavItem] = useState("Food")

    const handleNavItemClick = (navItem) => {
        setSelectedNavItem(navItem);
    };

    const navigation = ["Food", "Drinks", "Ambiance", "Event"]

    const navMenuStyle = {
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        display: 'flex',
        flexGrow: 1, /* Make the menu take up all available space */
        textAlign: 'center',
        justifyContent: 'center',
    }


    const navBarStyle = {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '33%',
        margin: '0 auto'
    }

    const batchSize = 4;

    const foodContext = require.context('../assets/images/food', false, /\.(png|jpe?g|svg)$/);
    const allFoodPhotos = foodContext.keys().map(key => ({ url: foodContext(key) }));
    let food_batches = []

    for (let i = 0; i < allFoodPhotos.length; i += batchSize) {
        food_batches.push(allFoodPhotos.slice(i, i + batchSize));
    }

    const food_photos1 = food_batches[0]
    const food_photos2 = food_batches[1]
    const food_photos3 = food_batches[2]
    const food_photos4 = food_batches[3]
    const food_photo1 = food_batches[4][0]
    const food_photo2 = food_batches[4][1]
    const food_photo3 = food_batches[4][2]
    const food_photo4 = food_batches[4][3]

    const drinkContext = require.context('../assets/images/drink', false, /\.(png|jpe?g|svg)$/);
    const allDrinkPhotos = drinkContext.keys().map(key => ({ url: drinkContext(key) }));
    let drink_batches = []

    for (let i = 0; i < allDrinkPhotos.length; i += batchSize) {
        drink_batches.push(allDrinkPhotos.slice(i, i + batchSize));
    }

    const drink_photos1 = drink_batches[0]
    const drink_photos2 = drink_batches[1]
    const drink_photos3 = drink_batches[2]
    const drink_photos4 = drink_batches[3]
    const drink_photo1 = drink_batches[4][0]
    const drink_photo2 = drink_batches[4][1]
    const drink_photo3 = drink_batches[4][2]
    const drink_photo4 = drink_batches[4][3]

    const ambianceContext = require.context('../assets/images/ambiance', false, /\.(png|jpe?g|svg)$/);
    const allambiancePhotos = ambianceContext.keys().map(key => ({ url: ambianceContext(key) }));
    let ambiance_batches = []

    for (let i = 0; i < allambiancePhotos.length; i += batchSize) {
        ambiance_batches.push(allambiancePhotos.slice(i, i + batchSize));
    }

    const ambiance_photos1 = ambiance_batches[0]
    const ambiance_photos2 = ambiance_batches[1]
    const ambiance_photos3 = ambiance_batches[2]
    const ambiance_photos4 = ambiance_batches[3]
    const ambiance_photo1 = ambiance_batches[4][0]
    const ambiance_photo2 = ambiance_batches[4][1]
    const ambiance_photo3 = ambiance_batches[4][2]
    const ambiance_photo4 = ambiance_batches[4][3]

    const eventContext = require.context('../assets/images/event', false, /\.(png|jpe?g|svg)$/);
    const alleventPhotos = eventContext.keys().map(key => ({ url: eventContext(key) }));
    let event_batches = []

    for (let i = 0; i < alleventPhotos.length; i += batchSize) {
        event_batches.push(alleventPhotos.slice(i, i + batchSize));
    }

    const event_photos1 = event_batches[0]
    const event_photos2 = event_batches[1]
    const event_photos3 = event_batches[2]
    const event_photos4 = event_batches[3]
    const event_photo1 = event_batches[4][0]
    const event_photo2 = event_batches[4][1]
    const event_photo3 = event_batches[4][2]
    const event_photo4 = event_batches[4][3]

    return (
      <>
        <div className="page-heading">Gallery</div>
        <NavigationBar
          handleNavItemClick={handleNavItemClick}
          navigation={navigation}
          navBarStyle={navBarStyle}
          navMenuStyle={navMenuStyle}
        />
        {selectedNavItem === "Food" && (
          <PhotoGallery
            photo1={food_photo1}
            photos1={food_photos1}
            photo2={food_photo2}
            photos2={food_photos2}
            photo3={food_photo3}
            photos3={food_photos3}
            photo4={food_photo4}
            photos4={food_photos4}
          />
        )}
        {selectedNavItem === "Drinks" && (
          <PhotoGallery
            photo1={drink_photo1}
            photos1={drink_photos1}
            photo2={drink_photo2}
            photos2={drink_photos2}
            photo3={drink_photo3}
            photos3={drink_photos3}
            photo4={drink_photo4}
            photos4={drink_photos4}
          />
        )}
        {selectedNavItem === "Ambiance" && (
          <PhotoGallery
            photo1={ambiance_photo1}
            photos1={ambiance_photos1}
            photo2={ambiance_photo2}
            photos2={ambiance_photos2}
            photo3={ambiance_photo3}
            photos3={ambiance_photos3}
            photo4={ambiance_photo4}
            photos4={ambiance_photos4}
          />
        )}
        {selectedNavItem === "Event" && (
          <PhotoGallery
            photo1={event_photo1}
            photos1={event_photos1}
            photo2={event_photo2}
            photos2={event_photos2}
            photo3={event_photo3}
            photos3={event_photos3}
            photo4={event_photo4}
            photos4={event_photos4}
          />
        )}
      </>
    );
}

export default Gallery;
