import React from 'react';
import '../assets/css/Events.css'

const Events = () => {

    const events = [
        { eventName: "Event1", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_1.png') },
        { eventName: "Event2", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_2.png') },
        { eventName: "Event3", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_3.png') },
        { eventName: "Event4", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_4.png') },
        { eventName: "Event5", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_5.png') },
        { eventName: "Event6", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_6.png') },
        { eventName: "Event7", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_7.png') },
        { eventName: "Event8", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_8.png') },
        { eventName: "Event9", eventTime: "09:00pm", eventDate: "23 Jan 2025", eventDay: "Monday", eventImg: require('../assets/images/event_9.png') },
    ]
    const eventElements = events.map(e => (
        <div className="box">
            <img src={e.eventImg} alt={e.eventName} />
            <div className='just-heading'>{e.eventName}</div>
            <p>{e.eventTime}</p>
            <p>{e.eventDate} {e.eventDay}</p>
        </div>
    ));

    return (
        <>
            <div className='page-heading'>Events</div>
            <div className="box-container">
                {eventElements}
            </div>
        </>
    );

}

export default Events;