import '../assets/css/ImgCaption.css'

const ImgCaption = ({ photo, image_type, caption_header, caption }) => {
    return (
        <div className='img_caption_container'>
            <div className="img_caption">
                <img src={photo} className={image_type} alt={caption_header} />
                <div className="captionWrapper">
                    <div className='just-heading'>{caption_header}</div>
                    <p className="caption">{caption}</p>
                </div>
            </div>
        </div>
    );
}

export default ImgCaption;