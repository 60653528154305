import React from 'react';

const SingleImageOneThird = ({ photo }) => {
    return (
        <div className="container">
            <div className='single-box'>
                <img src={photo.url} alt="Single" />
            </div>
        </div>
    );
};

export default SingleImageOneThird;
