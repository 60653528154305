import React, { useState, useEffect } from 'react';
import '../assets/css/Banner.css'; // Import CSS for styling

const Banner = ({ bannerImages }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === bannerImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [bannerImages]); // Use bannerImages as a dependency

  return (
    <div className="banner">
      {bannerImages.length > 0 && (
        <img
          src={bannerImages[currentImageIndex].url}
          alt={"banner image " + [currentImageIndex]}
          className="banner-image"
        />
      )}
    </div>
  );
};

export default Banner;
