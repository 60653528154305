import React from 'react';

const FourImagesTwoThirds = ({ photos }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="gallery-box" id="box1">
            <img src={photos[0].url} alt='event'/>
        </div>
        <div className="gallery-box" id="box2">
            <img src={photos[1].url} alt='event'/></div>
      </div>
      <div className="row">
        <div className="gallery-box" id="box3">
            <img src={photos[2].url} alt='event'/></div>
        <div className="gallery-box" id="box4">
            <img src={photos[3].url} alt='event'/></div>
      </div>
    </div>
  );
};

export default FourImagesTwoThirds;
