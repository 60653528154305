import '../assets/css/Menu.css'

const Menu = () => {
    return (
        <>
        <div className='page-heading'>Menu</div>
        <div className="menu-container">
            <h1>Coming Soon!</h1>
        </div>
        </>
    );
}

export default Menu;