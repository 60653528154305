import React from 'react';
import '../assets/css/Gallery.css'; // Import CSS file for styling
import FourImagesTwoThirds from './FourImagesTwoThirds';
import SingleImageOneThird from './SingleImageOneThird';

const PhotoGallery = ({photos1,photo1,photos2, photo2, photos3, photo3, photos4, photo4}) => {
    
    return (
        <div className='gallery-row'>
            <row>
                <FourImagesTwoThirds photos={photos1} />
                <SingleImageOneThird photo={photo1} />
            </row>
            <row>
                <SingleImageOneThird photo={photo2} />
                <FourImagesTwoThirds photos={photos2} />
            </row>
            <row>
                <FourImagesTwoThirds photos={photos3} />
                <SingleImageOneThird photo={photo3} />
            </row>
            <row>
                <SingleImageOneThird photo={photo4} />
                <FourImagesTwoThirds photos={photos4} />
            </row>
        </div>
    );
}
export default PhotoGallery