import React, {useState } from 'react';
import './assets/css/App.css';
import './components/Banner.js'
import HomePage from './components/HomePage.js';
import Events from './components/Events.js';
import Menu from './components/Menu.js';
import Contact from './components/Contact.js';
import Header from './components/Header.js';
import './assets/css/Modal.css'
import Footer from './components/Footer.js';
import Gallery from './components/Gallery.js';
import WhatsAppButton from './components/WhatsappButton.js';

function App() {
  const [selectedNavItem, setSelectedNavItem] = useState("Home")

  const handleNavItemClick = (navItem) => {
    setSelectedNavItem(navItem);
  };
  
  return (
    <div className="background-div">
      <Header handleNavItemClick={handleNavItemClick}/>
      {selectedNavItem === "Home" && <HomePage />}
      {selectedNavItem === "Events" && <Events />}
      {selectedNavItem === "Menu" && <Menu />}
      {selectedNavItem === "Gallery" && <Gallery/>}
      {selectedNavItem === "Contact" && <Contact />}
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default App;
