import React from 'react';
import ImgCaption from './ImgCaption';
import '../assets/css/Summary.css'; // Import CSS for styling
import '../assets/css/App.css'; // Import CSS for styling

const Summary = ({ summary_images }) => {

  return (
    <div className="image-gallery">
      {summary_images.map((image) => (
        <ImgCaption photo={image.src} image_type = "icon" caption_header={image.caption_header} caption={image.caption} />
      ))}
    </div>
  )
}

export default Summary;