import React, { useState } from 'react';
import Banner from './Banner.js';
import Summary from './Summary.js';
import '../assets/css/HomePage.css'
import '../assets/css/PhotoCaptionSide.css'
import axios from 'axios';
import Modal from 'react-modal';
import '../assets/css/App.css';
import './Banner.js'
import '../assets/css/Modal.css'
import endpoint from '../Endpoint.js';

const HomePage = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    const [msg, setMsg] = useState('');
    const [disabledSave, setDisabledSave] = useState(true);

    const isWideScreen = window.innerWidth >= 768;

    const home_page_banner_context = require.context('../assets/images/homepage_banner', false, /\.(png|jpe?g|svg)$/);
    const home_page_banner = home_page_banner_context.keys().map(key => ({ url: home_page_banner_context(key) }));

    const summary_images = [
        { src: require('../assets/images/summary_1.png'), caption_header: "Location", caption: "GNP Arcedia, Dombivali" },
        { src: require('../assets/images/summary_2.png'), caption_header: "Open Hours", caption: "12:00pm - 12:00am" },
        { src: require('../assets/images/summary_3.png'), caption_header: "Reservation", caption: "+91-000-000-0000" }

    ]

    const usp_img = require('../assets/images/usp.png')
    const story_img = require('../assets/images/our_story.png')



    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setName("");
        setPhoneNumber();
        setMsg("");
        setModalIsOpen(false);
        setDisabledSave(true);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        setMsg('');
        (name && phoneNumber && phoneNumber.length === 10) > 0 ? setDisabledSave(false) : setDisabledSave(true)
    }

    const handlePhoneNumberChange = (event) => {
        // Get the input value
        let inputValue = event.target.value;

        // Remove any non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');
        inputValue = inputValue.slice(0, 10); // Limit to maximum of 10 characters

        setMsg('');

        // Update the state with the sanitized input value
        setPhoneNumber(inputValue);
        // Log the current input value and the key being pressed
        (name && inputValue.length === 10) > 0 ? setDisabledSave(false) : setDisabledSave(true)
    };

    const saveContactsToFile = async () => {

        let user = {
            name: name,
            phoneNumber: phoneNumber
        }
        try {
            const response = await axios.post(endpoint + '/updatePhoneNumber', user);
            setDisabledSave(true);
            setMsg(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // Handle 409 Conflict error
                setMsg(error.response.data.message);
              } else {
                // Handle other errors
                console.error(error);
              }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        await saveContactsToFile(); // Wait for saveContactsToFile to complete
    };


    return (
        <>
            <div className='page-heading'>Home</div>
            <div className='banner'><Banner bannerImages={home_page_banner} /></div>
            <button onClick={openModal} className="button-link">Click Here for free drinks!</button>
            <Summary summary_images={summary_images} />
            <div className='img-caption-wrapper'>
                <img src={story_img} alt='Our Story' />
                <div className="caption">
                    <div className='page-subheading'>Our Story</div>
                    <p>The quick brown fox jumps over the lazy dog. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                </div>
            </div>
            <div className='banner'><img src={usp_img} alt='usp' /></div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modal-content">
                <div className='close-button'><button onClick={closeModal} className="close-button">x</button></div>
                <div>
                    <h2>Enter Details</h2>
                    <form onSubmit={handleSubmit}>
                        <div className='form-input-container' style={{ paddingLeft: isWideScreen ? '6vh' : '' }}>
                            <label>
                                Name:
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="Enter your name"
                                    onChange={handleNameChange} />
                            </label>
                        </div>
                        <div className='form-input-container'>
                            <label>
                                Phone Number:
                                <input
                                    type="tel" // Set the input type to "tel" for phone numbers
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    length="10" // Example pattern for XXX-XXX-XXXX format
                                    placeholder="Enter your 10 digit phone number"
                                    required // Optional: Make the field required
                                />
                            </label>
                        </div>
                        <div className='form-input-container'>
                            <button type="submit" disabled={disabledSave}>Save</button>
                        </div>
                        <div className='form-input-container'>
                            <p color='white'>{msg}</p>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )

}
export default HomePage;