import React from 'react';
import '../assets/css/Contact.css'

const Contact = () => {
  const  imageSrc = require('../assets/images/maps.png')
  return (
    <>
      <div className='page-heading'>Contact Us</div>
      <div className='contact-container'>
        <div className='contact-details-container'>
          <div className='contact-details'>
            <div className='page-subheading'>Address</div>
            <p>GNP, Arcadia, Dombivali East</p>
          </div>
          <div className='contact-details'>
            <div className='page-subheading'>Phone</div>
            <p>+91 000-000-0000</p>
          </div>
          <div className='contact-details'>
            <div className='page-subheading'>Email</div>
            <p>theBarSheriff@gmail.com</p>
          </div>
        </div>
        <div className='contact-location-container'>
          <div className='contact-location'>
            <a href={'https://www.google.com/maps/dir//6474%2B738+GNP+Arcadia,+Azde+Gaon,+Sudarshan+Nagar,+Dombivli+East,+Dombivli,+Maharashtra+421203/@19.2131558,73.1026248,17z/data=!4m17!1m7!3m6!1s0x3be79580ad97794b:0x90a7ec345d61357f!2sGNP+Arcadia!8m2!3d19.2131558!4d73.1051997!16s%2Fg%2F11h1sd78s7!4m8!1m0!1m5!1m1!1s0x3be79580ad97794b:0x90a7ec345d61357f!2m2!1d73.1051997!2d19.2131558!3e9?entry=ttu'} target="_blank" rel="noopener noreferrer">
              <img src={imageSrc} alt="location" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
