import React from 'react';
import '../assets/css/NavigationBar.css';
import '../assets/css/App.css'

const NavigationBar = ({ handleNavItemClick, navigation, navBarStyle, navMenuStyle }) => {
  return (
    <nav className="navbar" style={navBarStyle}>
      <ul className="nav-menu" style={navMenuStyle}>
        {navigation.map(navi => (
          <li key={navi} className="nav-item" onClick={() => handleNavItemClick(navi)}>
            <p className='nav-heading'>{navi}</p>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationBar;
