import React from 'react';
import '../assets/css/WhatAppButton.css'

const WhatsAppButton = () => {
  const phoneNumber = '7498595969';
  const message = 'Hello, I have a question.';

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <button onClick={handleClick} class="whatsapp-button"></button>
  );
};

export default WhatsAppButton;