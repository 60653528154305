import '../assets/css/Header.css'
import NavigationBar from '../components/NavigationBar';

const Header = ({ handleNavItemClick }) => {
    let logo = require('../assets/images/logo.png');
    let sheriff = require('../assets/images/sheriff.png');

    const navigation = ['Home', 'Events', 'Menu', 'Gallery', 'Contact'];

    const navMenuStyle = {
        listStyleType: 'none',
        color: 'antiquewhite',
        backgroundColor: '#393338',
        margin: '0',
        padding: '0',
        flexGrow: 1, /* Make the menu take up all available space */
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
    }

    const navbarStyle = {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    }

    return (
        <><header className="header">
            <img src={logo} alt='logo' className="logo" />
            <img src={sheriff} alt="sherif" className="sherif" />
        </header>

            <NavigationBar handleNavItemClick={handleNavItemClick} navigation={navigation} navBarStyle={navbarStyle} navMenuStyle={navMenuStyle} /></>
    );
}

export default Header;