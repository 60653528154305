import '../assets/css/Footer.css'

const SocialMedia = ({ logo, link, alt }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={logo} alt={alt} className='social-media' />
        </a>
    )
}

export default SocialMedia;