import React from 'react';
import '../assets/css/Footer.css'
import SocialMedia from './SocialMedia';

const Footer = () => {
    let facebook = { src: require('../assets/images/icon_1.png'), link: "https://www.facebook.com" }
    let instagram = { src: require('../assets/images/icon_1.png'), link: "https://www.instagram.com" }
    let x = { src: require('../assets/images/icon_1.png'), link: "https://www.x.com" }

    return (
        <footer>
            <div>
                <h5>Contact Us</h5>
                <p>GNP, Arcadia,</p>
                <p>Dombivali East</p>
                <p>Call: +91 000-000-0000</p>
            </div>
            <div>
            </div>
            <div>
                <h5>Follow Us</h5>
                <p>@TheBarSheriff</p>
                <SocialMedia link={facebook.link} logo={facebook.src} alt="Facebook" />
                <SocialMedia link={instagram.link} logo={instagram.src} alt="instagram" />
                <SocialMedia link={x.link} logo={x.src} alt="x" />
                {/* Add more social media placeholders as needed */}
            </div>
        </footer>
    );
}

export default Footer;